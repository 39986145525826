import React from "react"

import SEO from "../../../components/seo"
import Header from "../../../components/header"
import InfoModal from "../../../components/info-modal"

import { ImagePanorama, Viewer, Infospot } from "panolens"
import { navigate } from "gatsby"
import LoadingBar from "../../../components/loading-bar"
import PanoContainer from "../../../components/pano-container"
import { Howl } from "howler"
import QuestionModal from "../../../components/question-modal"
import ToolBar from "../../../components/tool-bar"

import { Vector3 } from "three"

// Custom Icons
import QuestionMark from "../../../../static/icons/question-mark-white.png"
import ArrowMark from "../../../../static/icons/arrow-mark-white.png"
import EmergencyMark from "../../../../static/icons/emergency-mark-white.png"

export default class ThePresents extends React.Component {
  constructor() {
    super()
    this.state = {
      currentQuestion: "",
      infoModalIsOpen: false,
      questionModalIsOpen: false,
      progress: 0,
    }
  }

  setPositionToTarget(target, viewer) {
    const { x, y, z } = target.normalize()
    viewer.camera.position.set(x, -y, -z)
  }

  createQuestionInfoSpot(question, position) {
    const questionInfoSpot = new Infospot(300, QuestionMark, false)
    questionInfoSpot.position.set(position.x, position.y, position.z)
    questionInfoSpot.addHoverText(question)
    questionInfoSpot.addEventListener("click", () => {
      this.setState({
        currentQuestion: question,
        questionModalIsOpen: true,
      })
    })

    return questionInfoSpot
  }

  componentDidMount() {
    // Creating the different Panorama objects
    const mainPanorama = new ImagePanorama(
      "/departments/the-presents/panorama.png"
    )
    const presentsPanorama = new ImagePanorama(
      "/departments/the-presents/presents-panorama.png"
    )

    // Progress Events
    mainPanorama.addEventListener("progress", progressEvent => {
      this.loadingProgress(progressEvent)
    })

    presentsPanorama.addEventListener("progress", progressEvent => {
      this.loadingProgress(progressEvent)
    })

    mainPanorama.setLinkingImage(ArrowMark, 350)
    presentsPanorama.setLinkingImage(ArrowMark, 350)

    // Linking Panorama's
    mainPanorama.link(presentsPanorama, new Vector3(4858.95, -1148.48, -126.44))
    presentsPanorama.link(mainPanorama, new Vector3(4765.88, -1474.93, 175.34))

    // Create the InfoSpot to Go to the next department
    const doorInfoSpot = new Infospot(350, ArrowMark, false)
    doorInfoSpot.position.set(-4898.12, -854.41, 495.3)
    doorInfoSpot.addHoverText("Go to the next department")
    doorInfoSpot.addEventListener("click", () => {
      navigate("/departments/ageing/")
    })

    const emergencyExitSpot = new Infospot(350, EmergencyMark, false)
    emergencyExitSpot.position.set(-2025.1, 2780.34, 3620.61)
    emergencyExitSpot.addHoverText("Go to the emergency exit")
    emergencyExitSpot.addEventListener("click", () => {
      navigate("/departments/the-emergency-exit/")
    })

    // Question Marks
    const surprisesQuestion = this.createQuestionInfoSpot(
      "I like surprises",
      new Vector3(4217.41, 1779.23, -1992.9)
    )

    const socialContractQuestion = this.createQuestionInfoSpot(
      "The act of giving is part of a social contract",
      new Vector3(3469.39, 15.95, 3591.1)
    )

    const presenceQuestion = this.createQuestionInfoSpot(
      "My presence is already a gift",
      new Vector3(-1869.85, -4538.14, -926.84)
    )

    // Add the InfoSpots to the Main Panorama
    mainPanorama.add(doorInfoSpot)
    mainPanorama.add(emergencyExitSpot)
    mainPanorama.add(surprisesQuestion)
    mainPanorama.add(socialContractQuestion)
    mainPanorama.add(presenceQuestion)

    const giftQuestion = this.createQuestionInfoSpot(
      "A gift without wrapping paper is actually not a gift",
      new Vector3(-2081.8, 732.4, 4480.82)
    )

    const envelopesQuestion = this.createQuestionInfoSpot(
      "Envelopes with money are actually the nr 1 on my wishlist",
      new Vector3(3245.89, -3391.06, 1700.36)
    )

    const emptyHandedQuestion = this.createQuestionInfoSpot(
      "I feel ashamed if I arrive empty handed on a birthday party",
      new Vector3(3435.03, -1188.83, -3422.63)
    )

    presentsPanorama.add(giftQuestion)
    presentsPanorama.add(envelopesQuestion)
    presentsPanorama.add(emptyHandedQuestion)

    const viewer = new Viewer({
      cameraFov: 90,
      container: document.getElementById("pano-container"),
      controlBar: false,
      output: "console",
      autoHideInfospot: false,
    })

    // Set initial Camera position
    this.setPositionToTarget(new Vector3(4982.35, 66.95, -286.52), viewer)

    viewer.add(mainPanorama)
    viewer.add(presentsPanorama)

    // Setup the new Howl.
    this.sound = new Howl({
      src: ["/departments/the-presents/the-presents.mp3"],
    })

    // Play the sound.
    this.sound.play()

    // Fade In
    this.sound.fade(0.0, 0.5, 2000)
  }

  componentWillUnmount() {
    this.sound.fade(0.5, 0.0, 1000)
  }

  loadingProgress(progressEvent) {
    var loaded = progressEvent.progress.loaded
    var total = progressEvent.progress.total
    var completedProgress = 365
    var percentage = Math.round((loaded / total) * completedProgress)

    this.setState({
      progress: percentage,
    })
  }

  closeInfoModal = () => {
    this.setState({
      infoModalIsOpen: false,
    })
  }

  closeQuestionModal = () => {
    this.setState({
      questionModalIsOpen: false,
    })
  }

  render() {
    return (
      <>
        <SEO title="The Presents" />
        <Header menuIsVisible={false} />
        <PanoContainer />
        <ToolBar
          onInfoClick={() =>
            this.setState({
              infoModalIsOpen: true,
            })
          }
          muteSound={() => this.sound.mute(!this.sound.mute())}
        />
        <LoadingBar progress={this.state.progress} />
        <InfoModal
          isOpen={this.state.infoModalIsOpen}
          onRequestClose={this.closeInfoModal}
          title="The Presents"
        >
          <p>
            The ‘present’. It refers to gifts, representing something, existing
            and being in the here- and-now. Intrinsically linked to the word
            ‘present’ are the two actor the ‘giver’ and the ‘receiver’.
            Translated to the birthday-context, this relation is often
            recognisable in the ‘giving guest’ and the ‘receiving host’. The
            host opens the door; the guest hands over the flowers or something
            else that could be wrapped in a nice paper. Why do we give each
            other gifts at a birthday? Isn’t our own presence just enough? What
            if the guest arrives ‘empty handed’? At least he could now use his
            hands to give a firm handshake. Thinking about that, to what extent
            is a gift actually an altruistic act? A social contract concealed by
            wrapping paper?
          </p>
        </InfoModal>
        <QuestionModal
          isOpen={this.state.questionModalIsOpen}
          onRequestClose={this.closeQuestionModal}
          question={this.state.currentQuestion}
        ></QuestionModal>
      </>
    )
  }
}
